.about-us-container{
    margin-top: 2.5rem;

    /* height: 5vh; */
    /* display: flex; */
}
footer{
    font-size: x-small;
}
.foottopic{
    color: rgb(255, 255, 255);
   
}

.footdesc{
    color:rgb(255, 255, 255);
}

.copy{
    margin-top: 3rem;
    color: rgb(255, 255, 255);
    text-align: center;
    
}

.social-links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: white;
    /* background-color: white; */
}
.social-links li{
    list-style: none;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}
.col-md-4{
    border: 1px white solid;
    text-align: center;
    width: 100%;
   
}
footer{
    bottom:0%;
    background-color: rgb(12, 12, 12);
}