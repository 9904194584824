.maps{
    width: 100%;
    height:auto;
    /* margin-left: 20%; */
    /* transform: translateX( -50%); */
    margin-top: 5vh;
    margin-bottom: 5vw;
  }
  
  .our{
    font-weight: bolder;
    font-style: italic;
    text-align: center;
    font-family: michroma, monospace;
    font-size: 6vw;
    color: white;
    width: 80%;
    
    margin-left: 50%;
    margin-top: 3vw;
    transform: translate(-50%, 0%);
  }
  .map  {
    color:white;
    width: 50%;
    margin-left: 25%;
    font-size: 1.2vw;
    font-weight: lighter;
    text-align: center;
    font-family: michroma, monospace;

  }
  .dealership-page {
    position: relative;
    padding: 50px; /* Adjust padding for responsiveness */
    color: white;
    min-height: 70vh;
    margin: 10px;
    overflow: hidden;
    font-style: italic;
    font-weight: bold;
  }
  
  .dealership-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('./carss.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
     /* Add color overlay */
  background-color: rgb(0, 0, 0);

    z-index: -1;
  }
  
  

  
 
  
  
  
  .search-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color:black;
  }
  
  
  
 #pincode{
    margin-bottom: 5px;
    color: white;
    margin-left: -40rem;
  }
  
 #pincode{
    padding: 10px; /* Adjust padding for better touch input */
    border: 1px solid #f8f5f5;
    color: rgb(0, 0, 0);
    width: 50%;
    margin-bottom: 10px;
    margin-left: 21%;
    z-index: 1;
  }
  .pam{
    position: absolute;
    top: 60vw;
    width: 100vw;
    height: 10vw;
    background-color: rgba(0, 0, 0, 1);
    z-index: -1;

  }
  #deal{
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    width: 50%;
    margin-left: 21.6%;
    z-index: 1;
  }
  
  .result-container {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 23rem;
    font-family: raleway;
    font-size: 1.5rem;
    z-index: 1;
  }
  
  .success{
    font-weight: bolder;
    color: rgb(65, 177, 21);
    margin-left: -20rem;
    font-size: larger;
    z-index: 1;
  }
  .error {
    font-weight: bold;
    color: red;
    margin-left: -20rem;
    font-size: larger;
    z-index: 1;
  }
  
  .address {
    margin-top: 10px;
    font-weight: bold;
    font: italic;
    margin-left: -20rem;
    color: white;
  }