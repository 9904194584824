.video-container {
  margin-top: 0rem;
  margin-bottom: 1rem;
  position: relative;
  /* z-index: 0; */
  height: 50vw;
  /* padding-bottom: 1%;  */
  overflow: hidden;
  opacity: 0.9;
}

video::-webkit-media-controls {
  display: none;
}
.video-overlay h1{
  font-size: 15vw;
  /* font-weight: lighter; */

  font-family: Michroma, monospace;
  color: white;
  text-align: center;
  margin-top: 5%;
  text-shadow:0px 0px 0px #000000;
  z-index: 2;
}
.video-overlay h3{
  position: relative;
  transform:  translateY(-12vw);
  letter-spacing: 0.3vw;
  font-size: 1.2vw;
  font-weight: lighter;
  font-family: Michroma, monospace;

  color: white;
  text-align: center;
  
  margin-top: 0%;
  text-shadow:0px 0px 0px #000000;
  z-index: 2;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  
  z-index: 1;

  text-shadow: (100px 100px 100px #000);
}
body{
  background-color: black ;
  overflow-x:hidden ;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  
}

::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.VideoButton{
  position: absolute;
  top: 40vw;
  left: 50%;
  
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.25);
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
  border: 1px solid white;
  border-radius: 1px;
  width: 18vw;
  height: 3.5vw;
  transform: translate(-50%, -50%);
  font-size: 1vw;
  font-weight: lighter;
  color: white;
  font-family: Michroma, monospace;
}

.VideoButton:hover{
 background-color:rgb(255,255,255,0.7) ;
 color:black;
 transition:0.82s;
}