.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border: rgba(0, 0, 0, 0);
  /* transition: 1s ease-in; */
}

.item {
  border: 1px solid #333;
  background-color: black;
  position: inherit;
  /* padding: 20px; */
  text-align: center;
  height: 45vh;
  color: white;
  transition: opacity 0.5s ease;
  border:none;
}

.item:hover {
  background-color: #ddd; /* Change background color on hover */
 
}
.item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.5s ease;
}

.item.show{
    opacity: 1;
}

.grid1 {
  width: 100%;
  height: 100%;
}

.item1{
    opacity: 2;
    
}

.grid4 {
  width: 100%;
  height: 100%;
}

.grid2 {
  width: 100%;
  height: 100%;
}

.grid3 {
    width: 100%;
    height: 100%;
  }
.grid-overlay{
  z-index: 2;
  position:relative;

  top:0;
  left:0;
  width:100%;
  height:100.04%;
  background-color: rgba(0, 0, 0, 0.25);
  transform: translate(0%, -107.75%);
}

.grid-overlay:hover{
  background-color: rgba(0, 0, 0, 0.85);
  transition: 0.3s ease-in;
}
.grid-overlay:hover p{
 opacity: 1;
  transition: 0.3s ease-in;
;

}
.grid-overlay hr{
  position: relative;
  opacity: 0;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 60%;
  border: 1px solid white;
  transition: 0.3s ease-in;

}
.grid-overlay:hover hr{
  opacity: 1;
  transition: 0.3s ease-in;
}

.grid-overlay h1{
  position: relative;
  font-family: Montserrat, monospace;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5vw;
  font-weight: 4000;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.7);
  transition: 0.2s ease-in;
}
.grid-overlay:hover h1{
  top:45%;
  transition: 0.2s ease-in;
}
.grid-overlay p {
  width:70%;
 opacity: 0;
  position: relative;
  font-family: Roboto, monospace;
  top: 30%;
  margin-top: 2rem;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 0.6rem;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  transition: 0.2s ease-in;
}

.grid-overlay button{
  position: relative;
  opacity: 0;
  
  height: 2rem;
  width: 12rem;
  top: 30%;
  left: 30%;
  transform: translate(-50%, 0);
  background-color: transparent;
  border: 1px solid white;
  border-radius: 8px;
  color: white;
  font-size: .7rem;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  transition: 0.2s ease-in;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
}
.grid-overlay:hover button{
 opacity: 1;
  transition: 0.3s ease-in;
}
.grid-overlay button:hover{
  background-color: white;
  color: black;
  transition: 0.2s ease-in;
}