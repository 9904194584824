@tailwind base;
@tailwind components;
@tailwind utilities;

.PreLogin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:9%;
}

.SignOutButton{
 position: absolute; 
  top: 9px;
  right: 10px;
  height: 30px;
  width: 80px;
  background-color: rgb(109, 0, 0,0.8);
  border-radius: 8px;
  border: none;
  color: white;
  z-index: 1000;
}
.SignOutButton :hover{
  background-color: rgb(76, 0, 255);
  cursor: pointer;
}

.SignUporLogInButton{
  margin-top: 50%;
  background-color: rgb(70, 20, 189,0.7); /* Green */
  padding: 5px;
  border:none;

  color: white;
  padding: 10px 10px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  z-index: 10;
  border-radius: 8px;
}
  #root{
    height: 100%;
  }
  body{
    height: 100%;
  }

  h88{
    font-size: 200px;
    font-family: Orbitron,monospace;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    margin-bottom: 0.5rem;
    position: absolute;
    z-index: 10;
    
    color:white;
  }
  



