.store {
  text-align: center;
  color: white  ;
  width: 75%;
  margin-left: 12.5%;
}

.store h1 {
  font-size: 3vw;
  margin-bottom: 1vw;
  width: max-content;
  font-family: michroma ;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 5%;
  margin-bottom: 5%;

}

#remote button{
  background-color: black;
  border: 1px solid #ccc;
  position: relative;
  height: 3.5vh;
  color: #fff;
  border: none;
  width: 8.5vw;
  min-width: 20px;
  margin-left: 5px;
  border-radius: 12px;
  font-size: 17px;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 5%;
}
#remote{
  margin-top: 5%;
  position: absolute;
  right: 5px;
  top: 3vw;
  
}

#remote button:hover {
  background-color: #0050a5;
}

.store-items .row {
  display:flex;
  width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    min-width: 450px;
    margin-bottom: 5vh;

   
}

.store-items .col{
  display: flex;
  justify-content: center;
  width: 45%;
  padding: 0 15px;
  margin-bottom: 10vh;
  min-width: 450px;
}

.store-items .card {
    width: 22vw;
    height: 47vh;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  min-width: 450px;
  margin-bottom: 5vh;
  margin-left: 1vw;
  margin-right: 1vw;
  height: auto;
}

.store-items .card img {
  width: 20vw;
  min-width: 450px;
  height: 270px;

  object-fit: cover;
}

.store-items .card h3 {
  font-size: 4vh;
  margin-bottom: 10px;
  margin-top: 10px;
}

.store-items .card p {
  font-size: 2.3vh;
  margin-bottom: 10px;
  margin-top: 10px;
}

.store-items .card button {
  background-color: #007bff;
  position: relative;
  color: #fff;
  border: none;
  padding: 15px 20px;
  border-radius: 12px;
  font-size: 18px;
  transform: translateY(-45%) ;


  top:4vh;
  cursor: pointer;
}

.store-items .card button:hover {
  background-color: #0050a5;
}
