.sustainability-description {
    color: white;
    background-color: black;
    margin: 3em;
    margin-left: 20rem;
    margin-right: 10rem;
    height: 12rem;
    border-radius: 15px;
    padding-top: 2.5rem;
    font-size: x-large;
    text-align: center;
    font-family: 'raleway', sans-serif;
}

.sustainability-title {
    text-decoration: underline;
    color: white;
    text-align: center;
    margin-top: 3rem;
}

.images {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.suses {
    width: 10rem;
    height: 10rem;
    border: 2px white solid;
    border-radius: 50%;
}

.recycle {
    background-color: #989090;
    color: black;
    margin: 4rem;
    margin-left: 10rem;
    margin-right: 10rem;
    height: 12rem;
    border-radius: 15px;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 50%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
    animation: reveal 2s ease-in-out forwards;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: x-large;
    font-family: 'raleway', sans-serif;

}

.sustainability-recycle {
    color: black;
}

.low {
    background-color: #B2B2B2;
    color: black;
    margin: 4rem;
    margin-left: 10rem;
    margin-right: 10rem;
    height: 12rem;
    border-radius: 15px;
    -webkit-mask-image: linear-gradient(to left, transparent 0%, rgb(0, 255, 34) 50%);
    mask-image: linear-gradient(to left, transparent 0%, rgb(8, 0, 124) 50%);
    animation: reveal 2s ease-in-out forwards;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: x-large;
    font-family: 'raleway', sans-serif;

}

.sustainability-low {
    color: black;
}

.recognition {
    background-color: rgb(255, 255, 255);
    color: black;
    margin: 4rem;
    margin-left: 10rem;
    margin-right: 10rem;
    height: 12rem;
    border-radius: 15px;
    padding-top: 2.5rem;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 50%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
    animation: reveal 2s ease-in-out forwards;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: x-large;
    font-family: 'raleway', sans-serif;

}

.sustainability-recognition {
    color: black;
}

.renewable {
    background-color: #F0F0F0;
    color: black;
    margin: 4rem;
    margin-left: 10rem;
    margin-right: 10rem;
    height: 12rem;
    border-radius: 15px;
    padding-top: 2.5rem;
    -webkit-mask-image: linear-gradient(to left, transparent 0%, black 50%);
    mask-image: linear-gradient(to left, transparent 0%, black 50%);
    animation: reveal 2s ease-in-out forwards;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: x-large;
    font-family: 'raleway', sans-serif;

}

.sustainability-renewable {
    color: black;
}

.community {
    background-color: #F8F8F8;
    color: black;
    margin: 4rem;
    margin-left: 10rem;
    margin-right: 10rem;
    height: 12rem;
    border-radius: 15px;
    padding-top: 2.5rem;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 50%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
    animation: reveal 2s ease-in-out forwards;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: x-large;
    font-family: 'raleway', sans-serif;

}

.sustainability-community {
    color: black;
}

@keyframes reveal {
    from {
        -webkit-mask-position: 100% 0;
        mask-position:  0 100%;
    }
    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}
