
.description-maindiv{
  display:flex;
  flex-direction: row;
  width: 319.7%;
  height: 55vh;

}

.description-div{
  color:white;
  height: 55vh;
  font-family: Michroma, monospace; 
  font-size: 2vh;
  width: 33.33%;
  text-align: center;
  justify-content: center;
  justify-items: center;

 
 
 
}

.description-div h1{
  font-size: 3vw;
  font-weight: 1000;

  line-height: 3;
  text-align: center;
  letter-spacing: 1.5vh;
 
  width: 100%;
  
}

.description-div p{
  font-size:2.2vh;
  color: rgb(182, 182, 182);
  font-weight: 10;
  line-height: 2;
  text-align: center;
  margin-left:50%;
  transform: translateX(-50%);
  width: 50%;

}
#desc-div2{
  background-color: white;
  color: black;
}
#desc-div2 p{
  color: rgb(97, 97, 97);
}
/* .carousel{
  height: 2rem;
} */
/* .carousel-inner{
  background-color: black;
  width: 100%;
  height: 28rem;

} */
/* 
.carousel-container {
  display: flex;
  justify-content: space-between;

} */

/* .carousel {
  width: 50%;

} */






/* @media screen and (max-width: 768px) {
  .navbar-brand {
    font-size: 3vw; /* Adjust font size for smaller screens} */


  /* .navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 2rem;
    margin-right: 2rem;
  }
} */
