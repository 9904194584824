
nav {
    z-index: 111;
    background-color:black;
    align-content: center;
    padding:0px;
    width: 100%;
    height: 35px;
    border: white;

    border-width: 10px;
    border-left: transparent;
    border-right: transparent;
    display: flex;
    justify-content: center;
    transform: translateY(-30%);
     
     
}
nav ul{
  width: 100%;
 
}
nav li{
    text-decoration: none;
    height: 100%;
    max-lines: 1;
   
    float: left;
    padding: 0px;
    margin:0%;
    justify-content: center;
}

.navbarNav{
width: 100%;

display: inline-block;

}

.navbar-nav{
  height: 100%;
  top:0;
  left:0;
}

.nav-link{
text-decoration: none;
padding: 5px;
margin: 0px;

padding: 10px;

color: white 
}
.nav-item{
  max-width: 180px;

  width: 19vw;
  height: auto; 

  
 
  max-lines: 1;
  line-height: 1;
  z-index: 111;
    display: block;
    background-color:black;
    color: white;
    text-align: center;
    padding: 0.2%;
    text-decoration: none;
    transition: 0.3s;
    border: none;
    font-family: 'Raleway', monospace;
    font-weight: lighter;
    font-size: 1.2vw;
 
  display: inline-block;

  cursor: crosshair;
}
.nav-item:hover{
    background-color: rgb(27, 27, 27);
   
   transition-duration: 0.2s;
}
#aeonixnav{
  font-size: larger;
  transform: translateY(-20px);
  font-family: 'Orbitron';
  font-weight: bold;

}