

#Car-Details-Container {
    position: absolute;
    top: 50px;
    right: 0;
    width: 400px;
    height: 100%;
    padding: 20px;
    background-color: rgba(12, 0, 0, 0.3);
    text-align: center;
    z-index: 1;
  }

  #Car-Details-Container #Car-Name {
    font-size: 75px;
   
    margin-right: 400px;
    font-family: orbitron,monospace;
    color:white;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;}

  #Car-Details-Container #Car-Specs-Container {
    
    color: white;
    font-family: Arial;
     /* rgba(92, 86, 86, 0.5); */
    padding: 20px;
    border-radius: 1px;
  }

  #Car-Details-Container #Car-Specs-Container #Car-Specs-Title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    
    font-size: 40px;
    font-weight: bold;
    align-items: center;
    margin-bottom: 30px;
  }

  
#Car-Details-Container #Car-Specs-Container #Car-Specs-List {
  
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Set 2 items per row */
  grid-gap: 10px; /* Add gap between items */
 
}
  

  #Car-Details-Container #Car-Specs-Container #Car-Specs-List #Car-Specs-Item {
    
    font-size: 20px;
    background-color: white;
    color: black;
    padding: 5px;
    text-align: center;
    line-height: 1.3;
    max-lines: 1;
    display: inline-block;
    vertical-align: middle;
    border-radius: 1px;
    
    height: 55px;
    width: auto;

  }

  #Car-Details-Container #Car-Specs-Container #Car-Specs-List #Car-Specs-Item:hover {
    background-color: #00C8FF;
    color: black;
    
    transition: 0.5s;
  }

  #Car-Details-Container #Car-Description {
   font-family: roboto,monospace;
    margin-bottom: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: rgb(218, 218, 218);
     /* rgba(92, 86, 86, 0.5); */
    padding: 20px;
    border-radius: 1px;
  }

  #Car-Details-Container #Car-Price {
    font-weight: bold;
    font-size: 40px;
    font-family: Orbitron,monospace;
    color : white;
    text-shadow: black 5px 5px 5px;
    margin-bottom: 10px;
  }

  #Car-Details-Container #Car-Buy-Button {
    width: 100%;
    height: 60px;
    font-size: 35px;
    color: black;
    background-color: white;
    float: bottom;
    border: none;
    bottom: 20px;
    border-radius: 1px;
    padding: 10px;
    cursor: pointer;
    
  }

  #Car-Details-Container #Car-Buy-Button:hover {
    color: black;
    padding: 10px;
    height: 70px;
    background-color: #00C8FF;
    
    transition: 0.2s;
  }


  #navigation{
    position: absolute;
    z-index: 10;
    display: flexbox;
    float: left;
    margin-left: 50px;
    ;
    width: 20%;
    height: 30%;
    margin-top:125px;
    justify-content: center;
  ;  
  }
  #navigation button {
  width: 10vw;
  height: 30px;
  font-size: 20px;
  
  background-color : white;
  color: #000000;
  border: none;
  margin: 10px;
  border-radius: 1px;
  cursor: crosshair;

  }
  #navigation button:hover{
    background-color: #00C8FF;
    color: #ffffff;
    width: 10.5vw;
    transform:translateX(20px);
    height: 32px;
    margin: 9px;
    transition: 0.2s;
  }

